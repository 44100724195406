import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import SEO from '../components/seo';
import Layout from '../components/layout';
import GeneralHead from '../components/generalHead';
import componentGenerator from '../utils/componentGenerator';

const TransformCTAText = styled.div`
  .btn {
    text-transform: none;
  }
`;

const ThankYouTemplate = ({
  data: {
    page: { components },
  },
  pageContext,
}) => {
  const isDark =
    components &&
    ((components[1]?.background &&
      Boolean(components[1].background.toLowerCase() !== 'light')) ||
      components[1]?.__typename === 'DatoCmsHeroCarouselComponent');
  return (
    <Layout>
      {componentGenerator(
        components,
        pageContext?.slug,
        ['thank-you', 'gracias'],
        isDark
      )}
    </Layout>
  );
};

export const Head = ({
  data: {
    page: { seo, headHtml, indexable, schemaMarkup },
  },
}) => (
  <GeneralHead>
    <SEO
      seo={seo}
      headHtml={headHtml}
      indexable={indexable}
      schemaMarkup={schemaMarkup}
    />
  </GeneralHead>
);

export default ThankYouTemplate;

export const query = graphql`
  query($id: String) {
    page: datoCmsPageTemplateThankYou(id: { eq: $id }) {
      id
      slug
      seo: seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      indexable
      headHtml
      schemaMarkup {
        id
        schema
      }
      components {
        __typename
        ... on DatoCmsHeaderComponent {
          __typename
          id
          announcement
          internalName
          menu {
            id
            internalName
            menuItem {
              internalName
              label
              link
              menuItemCallToAction {
                url
              }
              childMenuItem {
                internalName
                subhead
                label
                menuItemCallToAction {
                  label
                  url
                }
                childMenuItem {
                  hoverColor {
                    hex
                  }
                  internalName
                  label
                  subhead
                  iconImage {
                    image {
                      gatsbyImageData(
                        imgixParams: { fm: "webp", auto: "compress", q: 35 }
                      )
                      url
                      alt
                    }
                  }
                  fullImage {
                    image {
                      gatsbyImageData(
                        imgixParams: { fm: "webp", auto: "compress", q: 35 }
                      )
                      fluid(
                        imgixParams: { fm: "webp", auto: "compress", q: 35 }
                      ) {
                        ...GatsbyDatoCmsFluid
                      }
                      url
                    }
                  }
                  menuItemCallToAction {
                    url
                  }
                }
              }
            }
          }
          callToAction {
            ...datoCmsCallToAction
          }
        }
        ... on DatoCmsComponentHero {
          ...datoCmsComponentHero
        }
        ... on DatoCmsCardDeckComponent {
          ...datoCmsCardDeckComponent
        }
        ... on DatoCmsFooterComponent {
          ...datoCmsFooterComponent
        }
      }
    }
  }
`;
